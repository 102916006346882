<template>
    <div class="schedule">
        <span class="text store-name">{{brandName.toUpperCase()}} {{storeName}}</span>
        <span class="text" v-if="todaySchedule.isOpen">{{$t("storeScheduleOpenToday")}} {{todaySchedule.closeHour}} !</span>
        <span class="text" v-else>{{$t("storeScheduleClosedToday")}}</span>
        <span class="text opening-hours-label">{{$t("storeOpeningHours")}}</span>
        <template v-for="(day, key) in schedule">
            <section :key="key" class="day">
                <span class="text day-label">{{day.day}}</span>
                <span v-if="day.isOpen" class="opening-hours">{{`${day.openHour} - ${day.closeHour}`}}</span>
                <span v-else class="opening-hours">{{$t("storeScheduleClosed")}}</span>
            </section>
        </template>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                storeName: localStorage.getItem("DKC_food_city") || "Seclin",
                brandName: localStorage.getItem("DKC_food_brand") || this.$t("yourStore"),
                schedule: [
                    {
                        day: this.$t("days.monday"),
                        isOpen: true,
                        openHour: "07:00",
                        closeHour: "21:00"
                    },
                    {
                        day: this.$t("days.tuesday"),
                        isOpen: true,
                        openHour: "07:00",
                        closeHour: "21:00"
                    },
                    {
                        day: this.$t("days.wednesday"),
                        isOpen: true,
                        openHour: "07:00",
                        closeHour: "21:00"
                    },
                    {
                        day: this.$t("days.thursday"),
                        isOpen: true,
                        openHour: "07:00",
                        closeHour: "21:00"
                    },
                    {
                        day: this.$t("days.friday"),
                        isOpen: true,
                        openHour: "07:00",
                        closeHour: "21:00"
                    },
                    {
                        day: this.$t("days.saturday"),
                        isOpen: true,
                        openHour: "07:00",
                        closeHour: "21:00"
                    },
                    {
                        day: this.$t("days.sunday"),
                        isOpen: false,
                        openHour: "07:00",
                        closeHour: "21:00"
                    }
                ],
                today: ((new Date()).getDay() + 6) % 7
            }
        },
        computed:{
            todaySchedule(){
                return this.schedule[this.today]
            }
        }
    }
</script>